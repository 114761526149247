import React from "react";
import { Link } from "react-router-dom";

function ContactHeader() {
  return (
    <div className="d-flex connect align-items-center mobile-nav-contact ">
      <Link className="mobile-nav-contact" to="/">
        <div className="d-flex mx-2 ">
          <p className="mt-1 text-nowrap fw-bold border-bottom text-white border-danger border-3 px-2 pb-1">
            প্যাকেজ
          </p>
        </div>
      </Link>
      <Link
        className="mobile-nav-contact"
        target="_blank"
        to="https://mixitspicesbd.com/contact-us/"
      >
        <div className="d-flex mx-2 ">
          <p className="mt-1 text-nowrap fw-bold border-bottom border-danger text-white border-3 px-2 pb-1">
            যোগাযোগ করুন
          </p>
        </div>
      </Link>
    </div>
  );
}

export default ContactHeader;
