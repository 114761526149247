import React, { useState } from "react";
import { Link } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
// icon
import EmailIcon from "@mui/icons-material/Email";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

// components
import ContactHeader from "../ChildComponents/ContactHeader";

// image
import logoImage from "../Image/logo.jpg";

function Header() {
  const [mobileView, setMobileView] = useState(false);

  const mobileNav = () => {
    if (mobileView === false) {
      setMobileView(true);
    } else {
      setMobileView(false);
    }
  };
  return (
    <div className="shadow bg-brand">
      <div class="top-bg-brand"></div>
      <div class="container">
        <div class="row">
          <div class="col-sm-12 col-md-12 col-xl-4 logo mb-3 header-mobile justify-content-between">
            <Link className="d-flex" to="/">
              <img
                className="img-fluid"
                src={logoImage}
                alt="Opu Telecom & Training Center"
              />
            </Link>
            <MenuIcon
              onClick={mobileNav}
              className="mobile-nav-icon text-white"
            />
          </div>
          <div class="col-sm-12 col-md-12 col-xl-8 d-flex justify-content-end header-mobile">
            <ContactHeader />
          </div>
        </div>
      </div>
      {/* mobile nav */}
      {mobileView ? (
        <div className="mobile-nav-view d-flex justify-content-center fixed-top">
          <div className=" pt-5">
            <button
              onClick={mobileNav}
              className="btn btn-danger mt-4 ms-4 mb-5"
            >
              <CloseIcon />
            </button>
            <div>
              <Link onClick={mobileNav} to="/">
                <div className="d-flex mx-2 font-mobile">
                  <p className="mt-1 text-nowrap fw-bold border-bottom border-danger border-3 px-2 pb-1">
                    প্যাকেজ
                  </p>
                </div>
              </Link>
              <Link
                onClick={mobileNav}
                target="_blank"
                to="https://mixitspicesbd.com/contact-us/"
              >
                <div className="d-flex mx-2 font-mobile">
                  <p className="mt-1 text-nowrap fw-bold border-bottom border-danger border-3 px-2 pb-1">
                    যোগাযোগ করুন
                  </p>
                </div>
              </Link>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default Header;
