import React from "react";
import { Link } from "react-router-dom";
import SettingsPowerIcon from "@mui/icons-material/SettingsPower";
import LogoImage from "../Image/logo.jpg";
import { useNavigate } from "react-router-dom";

function AdminHeader() {
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };

  return (
    <div className="shadow">
      <div className="container">
        <div class="row">
          <div class="col-2 logo">
            <img className="img-fluid" src={LogoImage} alt="" />
          </div>
          <div class="col-10  d-flex justify-content-end">
            <nav className="navbar navbar-expand-lg">
              <ul className="navbar-nav me-3">
                <li className="nav-item">
                  <Link className="nav-link fw-bold" to="/">
                    Landing Page
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fw-bold" to="/admin">
                    Dashboad
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fw-bold" to="/admin/orders">
                    Orders
                  </Link>
                </li>
              </ul>
            </nav>
            <div>
              <button
                className="btn btn-danger mt-2 ms-2"
                onClick={handleLogout}
              >
                <SettingsPowerIcon />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminHeader;
