import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AdminHeader from "./AdminHeader";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { useNavigate } from "react-router-dom";
import ReplyIcon from "@mui/icons-material/Reply";
import SaveIcon from "@mui/icons-material/Save";
import axios from "axios";
const baseURL = process.env.REACT_APP_API;

function StudentsPage() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editableMode, setEditableMode] = useState(false);
  const [orderStatuss, setOrderStatus] = useState("");

  const fetchStudentData = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(`${baseURL}/api/mixit/oder/${id}`, {
        headers: { Authorization: token },
      });
      setData(response.data.data);
      console.log(response.data.data);
    } catch (err) {
      setError("Failed to fetch student data");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchStudentData();
  }, [id]);

  const ediHandaler = () => {
    setEditableMode(true);
  };

  const handleUpdate = async () => {
    const token = localStorage.getItem("token");
    const orderStatus = orderStatuss;
    try {
      await axios.patch(
        `${baseURL}/api/mixit/oder/${id}`,
        { orderStatus },
        {
          headers: { Authorization: token },
        }
      );
      fetchStudentData();
      setEditableMode(false);
    } catch (err) {
      console.error(err);
      alert("Failed to update student data");
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  const formatDate = (dateString) => {
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    return new Date(dateString).toLocaleDateString("en-GB", options);
  };

  const handleStatusChange = (event) => {
    setOrderStatus(event.target.value);
    console.log("Selected Order Status:", event.target.value);
  };

  return (
    <div>
      <AdminHeader />
      <div className="my-5 container">
        <button
          className="btn btn-light mb-3 border border-primary"
          onClick={() => navigate(-1)}
        >
          <ReplyIcon />
        </button>
        <div className="row">
          <div className="col-sm-12 mb-3">
            <div className="shadow m-2 p-3 rounded">
              <p className="h6 fw-bold border-bottom border-danger mb-4">
                গ্রাহক তথ্য
              </p>

              <div class="row">
                <div class="col-sm-6">
                  <p>
                    অর্ডার তারিখ :-{" "}
                    <span className="fw-bold text-primary">
                      {formatDate(data.createdAt)}
                    </span>
                  </p>
                  <p>
                    অর্ডার আইডি :-{" "}
                    <span className="fw-bold text-primary">{data.orderId}</span>
                  </p>
                  <p>
                    গ্রাহকের নাম :{" "}
                    <span className="fw-bold text-primary">
                      {data.customerName}
                    </span>
                  </p>
                  <p>
                    গ্রাহকের মোবাইল নং :{" "}
                    <span className="fw-bold text-primary">
                      {data.customerMobileNumber}
                    </span>
                  </p>
                  <p>
                    অর্ডার স্ট্যাটাস :{" "}
                    {editableMode ? (
                      <select
                        className="form-select form-select-sm mt-3"
                        aria-label="Small select example"
                        value={orderStatuss} // Set the value to the state variable
                        onChange={handleStatusChange} // Add onChange handler
                      >
                        <option value="" disabled>
                          Change Order Status
                        </option>
                        <option value="Order Done">Order Done</option>
                        <option value="Transfer for Courier">
                          Transfer for Courier
                        </option>
                        <option value="Processing">Processing</option>
                        <option value="Pending">Pending</option>
                        <option value="Pending">Cencel</option>
                      </select>
                    ) : (
                      <span className="fw-bold text-primary">
                        {data.orderStatus}
                      </span>
                    )}
                  </p>
                </div>
                <div class="col-sm-6">
                  <p>
                    পেমেন্ট টাইপ :-{" "}
                    <span className="fw-bold text-primary">
                      {data.paymentType}
                    </span>
                  </p>
                  <p>
                    ট্রানসাকশান আইডি:-{" "}
                    <span className="fw-bold text-primary">
                      {data.paymentTnxNumber}
                    </span>
                  </p>
                  <p>
                    গ্রাহকের ঠিকানা :{" "}
                    <span className="fw-bold text-primary">
                      {data.customerAddress}
                    </span>
                  </p>
                  <p>
                    গ্রাহকের জেলা :{" "}
                    <span className="fw-bold text-primary">
                      {data.customerCity}
                    </span>
                  </p>
                  <p>
                    অর্ডার নোট :{" "}
                    <span className="fw-bold text-primary">
                      {data.orderNote}
                    </span>
                  </p>
                </div>
              </div>
            </div>
            {/* <button className="mt-5 btn btn-success" onClick={handleUpdate}>
              <EditIcon />
            </button> */}
          </div>
          <div className="col-sm-12 mb-3">
            <div className="shadow m-2 p-3 rounded">
              <p className="h6 fw-bold border-bottom border-danger mb-4">
                অর্ডার বিবরণ
              </p>
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">ক্রম</th>
                    <th scope="col">আইটেম বিবরণ</th>
                    <th scope="col">পরিমাণ</th>
                    <th scope="col">মোট মূল্য</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">1</th>
                    <td> {data.itemTitle}</td>
                    <td> {data.totalQuentity}</td>
                    <td> {data.totalProductPrice}/-</td>
                  </tr>
                  <tr>
                    <th scope="row"></th>
                    <td colspan="2" className="fw-bold text-end">
                      সর্বমোট =
                    </td>
                    <td>{data.totalProductPrice}/-</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <button
              className="mt-5 btn btn-success"
              onClick={editableMode ? handleUpdate : ediHandaler}
            >
              {editableMode ? <SaveIcon /> : <BorderColorIcon />}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StudentsPage;
