import React, { useState } from "react";
import axios from "axios";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

import Banner02 from "../Image/banner02.jpg";
import Banner01 from "../Image/banner01.jpg";

const baseURL = process.env.REACT_APP_API;

function RegForm() {
  const [totalQuentity, setTotalQuentity] = useState(1);
  const [totalDiscountProductPrice, setTotalDiscountProductPrice] = useState(0);
  const [customerName, setCustomerName] = useState("");
  const [customerMobileNumber, setCustomerMobileNumber] = useState("");
  const [customerAddress, setCustomerAddress] = useState("");
  const [customerCity, setCustomerCity] = useState("");
  const [orderNote, setOrderNote] = useState("");
  const [paymentType, setPaymentType] = useState("");
  const [paymentTnxNumber, setPaymentTnxNumber] = useState("");
  const [itemTitle, setItemTitle] = useState("");

  const [massege, setMassege] = useState("");
  const [loading, setLoading] = useState(false);
  const [paymentDetails, setPaymentDetails] = useState(false);
  const [checkedItems, setCheckedItems] = useState({
    package1: false,
    package2: false,
  });

  const prices = {
    package1: 1200,
    package2: 660,
  };

  const handleCheckboxChange = (event) => {
    const { id, checked } = event.target;

    setCheckedItems((prev) => {
      const updatedCheckedItems = {
        ...prev,
        [id]: checked,
      };
      const updatedTotal =
        (updatedCheckedItems.package1 ? prices.package1 : 0) +
        (updatedCheckedItems.package2 ? prices.package2 : 0);
      setTotalDiscountProductPrice(updatedTotal);

      if (updatedCheckedItems.package1 && updatedCheckedItems.package2) {
        setItemTitle(
          "৪০০ গ্রাম মসলার কম্ব প্যাকেজ, মিক্সট মসলা কম্বো প্যাকেজ ১০০ গ্রাম "
        );
      } else if (updatedCheckedItems.package1) {
        setItemTitle("৪০০ গ্রাম মসলার কম্ব প্যাকেজ");
      } else if (updatedCheckedItems.package2) {
        setItemTitle("মিক্সট মসলা কম্বো প্যাকেজ ১০০ গ্রাম");
      } else {
        setItemTitle(""); // Reset if no checkbox is selected
      }

      return updatedCheckedItems;
    });
  };

  const handlePaymentChange = (event) => {
    const selectedPaymentType = event.target.value;
    setPaymentType(selectedPaymentType);
    if (selectedPaymentType === "Bkash") {
      setPaymentDetails(true);
    } else {
      setPaymentDetails(false);
    }
  };

  const counterHandaler = (value) => {
    if (totalQuentity === 0) {
      return setTotalQuentity(1);
    }
    setTotalQuentity(totalQuentity + value);
  };

  const submitHandaler = async (e) => {
    e.preventDefault();
    setLoading(true);
    const totalProductPrice = totalDiscountProductPrice * totalQuentity;
    if (totalDiscountProductPrice === 0) {
      setLoading(false);
      return setMassege(
        "*** Please Select Order Package and Number of total Quantity !!"
      );
    }

    if (
      !itemTitle ||
      !totalQuentity ||
      !totalProductPrice ||
      !customerName ||
      !customerMobileNumber ||
      !customerAddress ||
      !customerCity ||
      !paymentType
    ) {
      setLoading(false);
      return setMassege("*** All Filds are required !!");
    }

    try {
      const response = await axios.post(`${baseURL}/api/mixit/oder`, {
        itemTitle,
        totalQuentity,
        totalProductPrice,
        customerName,
        customerMobileNumber,
        customerAddress,
        customerCity,
        orderNote,
        paymentType,
        paymentTnxNumber,
      });

      if (response.status === 200) {
        setMassege("Order Successfully Complete");
      }
      reset();
    } catch (error) {
      setMassege(error.massege);
    } finally {
      setLoading(false);
    }
  };

  const reset = () => {
    setTotalQuentity(1);
    setTotalDiscountProductPrice(0);
    setCustomerName("");
    setCustomerMobileNumber("");
    setCustomerAddress("");
    setCustomerCity("");
    setOrderNote("");
    setPaymentType("");
    setPaymentTnxNumber("");
    setItemTitle("");
  };

  return (
    <div className=" reg-form mb-5 bg-light">
      <div className="container">
        {/* pakage Select */}
        <div className="row mt-3 pt-3 mb-5">
          <p className="fw-bold my-4 h6 text-center">প্যাকেজ নির্বাচন করুন :</p>

          <div className="form-check d-flex justify-content-center">
            <input
              className="form-check-input"
              type="checkbox"
              id="package1"
              checked={checkedItems.package1}
              onChange={handleCheckboxChange}
            />
            <label className="form-check-label mb-3 ms-2" htmlFor="package1">
              ৪০০ গ্রাম মসলার কম্ব প্যাকেজ । (৳{prices.package1})
            </label>
          </div>

          <div className="form-check d-flex justify-content-center">
            <input
              className="form-check-input"
              type="checkbox"
              id="package2"
              checked={checkedItems.package2}
              onChange={handleCheckboxChange}
            />
            <label className="form-check-label mb-3 ms-2" htmlFor="package2">
              মিক্সট মসলা কম্বো প্যাকেজ ১০০ গ্রাম !!! (৳{prices.package2})
            </label>
          </div>

          {/* Display the calculated total price */}
          <div className="text-center mt-4">
            <p className="mb-0">মূল্য : ৳{totalDiscountProductPrice}</p>
          </div>
        </div>

        {/* order form */}
        <p className="fw-bold my-3 h4 text-center">: অর্ডার পরিমাণ :</p>
        <div className="text-center pt-3">
          <button
            onClick={() => counterHandaler(1)}
            className="btn btn-danger mx-2"
          >
            <AddIcon />
          </button>
          <span className="mx-2 fw-bold border border-danger p-3 rounded">
            {totalQuentity}
          </span>
          <button
            onClick={() => counterHandaler(-1)}
            className="btn btn-danger mx-2"
          >
            {" "}
            <RemoveIcon />
          </button>
        </div>
        <div className="text-center pt-4">
          <p className="mb-0">মূল্য : {totalDiscountProductPrice} </p>
          <p className="mb-0">পরিমাণ : {totalQuentity}</p>
          <p className="mb-0 mt-3">
            মোট মূল্য : ‍
            <span className="orderPrice">
              {totalDiscountProductPrice * totalQuentity}/- টাকা
            </span>
          </p>
        </div>

        <div className="row ">
          {/* Left Column */}
          <div className="col-sm-12 col-md-6 mb-3">
            {/* <p className="fw-bold">Course Information (কোর্সের তথ্য)</p> */}
            <div className="mb-3 mt-5 ">
              <label htmlFor="regDate" className="form-label">
                গ্রাহকের নাম
              </label>
              <input
                type="text"
                className="form-control form-control-sm shadow-sm mb-4"
                id="fatherName"
                required
                placeholder="নাম : মোহাম্মদ আমিন"
                value={customerName}
                onChange={(e) => setCustomerName(e.target.value)}
              />
              <label htmlFor="courseName" className="form-label">
                গ্রাহকের মোবাইল নাম্বার
              </label>
              <input
                type="text"
                className="form-control form-control-sm shadow-sm mb-4"
                id="fatherName"
                required
                placeholder="মোবাইল : +8801234567890"
                value={customerMobileNumber}
                onChange={(e) => setCustomerMobileNumber(e.target.value)}
                onKeyPress={(e) => {
                  if (!/[0-9]/.test(e.key)) {
                    e.preventDefault();
                  }
                }}
              />

              <label htmlFor="applicantType" className="form-label">
                পণ্য ডেলিভারির সম্পূর্ণ ঠিকানা
              </label>
              <input
                type="text"
                className="form-control form-control-sm shadow-sm mb-4"
                id="fatherName"
                required
                placeholder="ঠিকানা : বাসা নং, রোড নং, এলাকা, ডাকঘর, জেলা"
                value={customerAddress}
                onChange={(e) => setCustomerAddress(e.target.value)}
              />

              <label htmlFor="trainingSession" className="form-label">
                জেলা
              </label>
              <input
                type="text"
                className="form-control form-control-sm shadow-sm mb-4"
                id="fatherName"
                required
                placeholder="জেলা : চট্টগ্রাম"
                value={customerCity}
                onChange={(e) => setCustomerCity(e.target.value)}
              />
            </div>
          </div>
          {/* Middle Column */}
          <div className="col-sm-12 col-md-6 mb-3">
            {/* <p className="fw-bold">Personal Information : (ব্যক্তিগত তথ্য)</p> */}
            <div className="mb-3 mt-5">
              <label htmlFor="applicantName" className="form-label">
                অর্ডার নোট
              </label>{" "}
              <textarea
                class="form-control mb-3"
                id="exampleFormControlTextarea1"
                rows="3"
                placeholder="নোট : এখানে অর্ডার পৌঁছানোর বর্ণনা দিতে হবে "
                value={orderNote}
                onChange={(e) => setOrderNote(e.target.value)}
              ></textarea>
              <label htmlFor="applicantName" className="form-label">
                পেমেন্ট টাইপ
              </label>{" "}
              <br />
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="inlineRadioOptions"
                  id="inlineRadio1"
                  value="Cash On Delivery"
                  onChange={handlePaymentChange}
                />
                <label class="form-check-label" for="inlineRadio1">
                  Cash On Delivery
                </label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="inlineRadioOptions"
                  id="inlineRadio2"
                  value="Bkash"
                  onChange={handlePaymentChange}
                />
                <label class="form-check-label" for="inlineRadio2">
                  Bkash Payment
                </label>
              </div>{" "}
              <br /> <br />
              {paymentDetails ? (
                <>
                  <div className="bg-light shadow p-2 border border-danger rounded mb-3">
                    <p className="mb-0">1. Press *247#</p>
                    <p className="mb-0">2. Select Payment</p>
                    <p className="mb-0">
                      3. Enter <span className="fw-bold">01608-939027</span>
                    </p>
                    <p className="mb-0">
                      4. Enter{" "}
                      <span className="fw-bold">
                        {" "}
                        {totalDiscountProductPrice * totalQuentity}/- Taka{" "}
                      </span>
                    </p>
                    <p className="mb-0">5. Enter Ref #PG1</p>
                    <p className="mb-0">6. Enter Your PIN </p>
                    <p className="mb-0">7. Send </p>
                  </div>
                  <label htmlFor="fatherName" className="form-label">
                    Transaction ID (ex: trx-649841)
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-sm shadow-sm mb-4"
                    id="fatherName"
                    required
                    placeholder="নাম : মোহাম্মদ আমিন"
                    value={paymentTnxNumber}
                    onChange={(e) => setPaymentTnxNumber(e.target.value)}
                  />
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <p className="fw-bold text-danger text-end">{massege}</p>
        <div className="d-flex justify-content-end">
          <button
            onClick={submitHandaler}
            className="btn btn-danger shadow mb-5"
          >
            {loading ? "Loading...." : "অর্ডার করুন"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default RegForm;
