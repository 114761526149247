import React, { useEffect, useState } from "react";
import axios from "axios";
import Regimage from "../Image/reg.png";
import active from "../Image/active.png";
import compleate from "../Image/compleate.png";

const baseURL = process.env.REACT_APP_API;

function Counter() {
  const [totalOrder, seTotalOrder] = useState(0);
  const [todayOrder, setTodayOrder] = useState(0);
  const [sevenDaysOrder, setSvenDaysOrder] = useState(0);

  const getData = async (e) => {
    try {
      const response = await axios.get(`${baseURL}/api/mixit/oder/total`);
      const countData = response.data;
      seTotalOrder(countData.totalOrders);
      setTodayOrder(countData.todayOrders);
      setSvenDaysOrder(countData.last7DaysOrders);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="container">
      <div class="row">
        <div class="col-sm-4 mb-3">
          <div class="counter-container border border-danger rounded p-3 text-center shadow">
            <div class="row">
              <div class="col-6">
                <img className="img-fluid" src={Regimage} alt="" />
              </div>
              <div class="col-6 countingValue">
                <p className=" fw-bold">Total Orders </p>
                <p className=" fw-bold">{totalOrder}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-4 mb-3">
          <div class="counter-container border border-danger rounded p-3 text-center shadow">
            <div class="row">
              <div class="col-6">
                <img className="img-fluid" src={active} alt="" />
              </div>
              <div class="col-6 countingValue">
                <p className=" fw-bold">Today Total Orders </p>
                <p className=" fw-bold">{todayOrder}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-4 mb-3">
          <div class="counter-container border border-danger rounded p-3 text-center shadow">
            <div class="row">
              <div class="col-6">
                <img className="img-fluid" src={compleate} alt="" />
              </div>
              <div class="col-6 countingValue">
                <p className=" fw-bold">Last 7 Days Orders </p>
                <p className=" fw-bold">{sevenDaysOrder}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Counter;
