import React from "react";
import Banner02 from "../Image/banner02.jpg";
import Banner01 from "../Image/banner01.jpg";

function PakageDetails() {
  return (
    <div className="mt-5 pb-3 container pakage">
      <div class="row">
        <div class="col-sm-12 col-md-6 mb-3 border-end border-danger">
          <h2 className="fw-bold text-danger h2 mb-3">
            ৪০০ গ্রাম মসলার কম্ব প্যাকেজ !!!
          </h2>
          <div>
            <img className="img-fluid shadow rounded" src={Banner02} alt="" />
          </div>
          <div className="mt-5">
            <p className="fw-bold border-bottom border-3 border-dark">
              {" "}
              প্যাকেজটিতে যা থাকছে..
            </p>

            <table class="table">
              <thead>
                <tr>
                  <th scope="col">মসলার নাম</th>
                  <th scope="col">অফার </th>
                  <th scope="col">রেগুলার</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">হলুদ</th>
                  <td> ২৪০/-</td>
                  <td>২৬০/-</td>
                </tr>
                <tr>
                  <th scope="row">মরিচ</th>
                  <td>২৭০/-</td>
                  <td>৩৮০/-</td>
                </tr>
                <tr>
                  <th scope="row">ধনে </th>
                  <td>২০০/-</td>
                  <td> ২৬০/-</td>
                </tr>
                <tr>
                  <th scope="row"> জিরা </th>
                  <td>৫৭০/-</td>
                  <td> ৬৮০/-</td>
                </tr>
                <tr>
                  <th scope="row" className="text-end fw-bold">
                    {" "}
                    সর্বমোট ={" "}
                  </th>
                  <td className=" fw-bold">১,২৮০/-</td>
                  <td className=" fw-bold"> ১,৫৮০/-</td>
                </tr>
              </tbody>
            </table>
            <p>
              সাথে ডেলিভারি চার্জ = ৮০/- টাকা<span className="free"> ফ্রি</span>
            </p>
            <p>
              অর্থাৎ = <span className="final-price">১২০০/-</span> টাকায় পেয়ে
              যাচ্ছেন ১৫৮০ টাকার প্রোডাক্ট ।
            </p>
          </div>
        </div>
        <div class="col-sm-12 col-md-6">
          <h2 className="fw-bold text-danger h2 mb-3">
            মিক্সট মসলা কম্বো প্যাকেজ ১০০ গ্রাম !!!
          </h2>
          <div>
            <img className="img-fluid shadow rounded" src={Banner01} alt="" />
          </div>
          <div className="mt-5">
            <p className="fw-bold border-bottom border-3 border-dark">
              {" "}
              প্যাকেজটিতে যা থাকছে..
            </p>

            <table class="table">
              <thead>
                <tr>
                  <th scope="col">মসলার নাম</th>
                  <th scope="col">অফার </th>
                  <th scope="col">রেগুলার</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">গরম মসলা </th>
                  <td> ২৪০/-</td>
                  <td>৩০০/-</td>
                </tr>
                <tr>
                  <th scope="row">মাংসের মসলা </th>
                  <td>২৪০/-</td>
                  <td>৩০০/-</td>
                </tr>
                <tr>
                  <th scope="row">বিরিরানি মসলা </th>
                  <td>২৬০/-</td>
                  <td> ৩৪০ /-</td>
                </tr>
                <tr>
                  <th scope="row" className="text-end fw-bold">
                    {" "}
                    সর্বমোট ={" "}
                  </th>
                  <td className=" fw-bold"> ৭৪০/-</td>
                  <td className=" fw-bold"> ৯৪০/-</td>
                </tr>
              </tbody>
            </table>
            <p className="mt-5">
              সাথে ডেলিভারি চার্জ = ৮০/- টাকা<span className="free"> ফ্রি</span>
            </p>
            <p>
              অর্থাৎ <span className="final-price">৬৬০/-</span> টাকায় পেয়ে
              যাচ্ছেন মিক্স মসলার কম্বো প্যাকেজ ।
            </p>
          </div>
        </div>
        <p className="mt-5">
          দুটি কম্ব প্যাকেজ একসাথে নিলে গিফট হিসেবে থাকছে{" "}
          <span className="fw-bold">৩৪০/-</span> টাকা মূল্যের একটি 100 গ্রাম
          বিরিয়ানি মসলা <span className="free">ফ্রি </span>
        </p>
        <p>
          পরবর্তী ৩ মাস পর্যন্ত সকল প্রোডাক্টে{" "}
          <span className="fw-bo">১৫% </span> পর্যন্ত ডিসকাউন্ট এবং ফ্রি হোম
          ডেলিভারি সুবিধা ।
        </p>
        <p>
          এবং এদের মধ্যে তিন জন সবচেয়ে সুন্দর রিভিউদাতার জন্য থাকছে আকর্ষণীয়
          পুরস্কার ।
        </p>
        <p className="mt-5 pt-2">
          <span className="fw-bold">প্রথম পুরস্কার -</span> সবচেয়ে সুন্দর
          রিভিউদাতার জন্য থাকছে কক্সবাজার এয়ারের আপডাউন টিকেট ফ্রি
        </p>
        <p>
          <span className="fw-bold">দ্বিতীয় পুরস্কার - </span> হোটেল পেনিসুলাতে
          গেস্টসহ বুফে ডিনার ।
        </p>
        <p>
          <span className="fw-bold">তৃতীয় পুরস্কার - </span> একটি ফ্রাইপেন
        </p>
      </div>
    </div>
  );
}

export default PakageDetails;
