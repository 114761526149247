import React from "react";
import Header from "../Components/Header";
import Hero from "./Hero";
import RegForm from "../ChildComponents/RegForm";
import Counter from "./Counter";

import PakageDetails from "../ChildComponents/PakageDetails";

function LandingPage() {
  return (
    <div>
      <Header />
      <PakageDetails />
      <RegForm />
    </div>
  );
}

export default LandingPage;
