import React from "react";

import Header from "./Header";
import PakageDetails from "../ChildComponents/PakageDetails";
import RegForm from "../ChildComponents/RegForm";

function PakageOne() {
  return (
    <div>
      <Header />
      <PakageDetails />
      <RegForm />
    </div>
  );
}

export default PakageOne;
