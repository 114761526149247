import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios"; // Moved to the top

const baseURL = process.env.REACT_APP_API;

function RouteProtector(props) {
  const { Components } = props;
  const { id } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    checkTokenValidity(token);
  }, [id]);

  const checkTokenValidity = async (token) => {
    try {
      await axios.get(`${baseURL}/api/routeprotection`, {
        headers: {
          Authorization: token,
        },
      });
    } catch (error) {
      navigate("/login");
    }
  };

  return (
    <div>
      <Components />
    </div>
  );
}

export default RouteProtector;
