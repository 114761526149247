import React from "react";
import { Link } from "react-router-dom";

import Banner01 from "../Image/banner01.jpg";
import Banner02 from "../Image/banner02.jpg";

function Hero() {
  return (
    <div className="my-5 pb-5">
      <div class="container ">
        <h2 className="fw-bold h2 text-center mb-4">
          দেশের সেরা কম্বো প্যাকেজ এখন এইখানেই !!
        </h2>
        <div class="row">
          <div class="col-sm-12 col-md-6 ">
            <div class="mb-3 shadow rounded">
              <Link to="/pakage01">
                <img className="img-fluid rounded" src={Banner01} alt="" />
              </Link>
            </div>
          </div>
          <div class="col-sm-12 col-md-6 ">
            <div class="mb-3 shadow rounded">
              <Link to="/">
                <img className="img-fluid rounded" src={Banner01} alt="" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hero;
