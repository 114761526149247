import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Routes, Route, Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// components
// admin Panel
import Dashboard from "./Components/Dashboard";
import Students from "./Components/Students";
import StudentsPage from "./Components/StudentsPage";
import Login from "./Components/Login";
// user Panel
import LandingPage from "./Components/LandingPage";
import Footer from "./ChildComponents/Footer";
import PakageOne from "./Components/PakageOne";

// condisonal Components
import LoginRouteProtection from "./ChildComponents/LoginRouteProtection";
import RouteProtector from "./ChildComponents/RouteProtector";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/pakage01" element={<PakageOne />} />

        <Route
          path="/login"
          element={<LoginRouteProtection Components={Login} />}
        />
        <Route
          path="/admin"
          element={<RouteProtector Components={Dashboard} />}
        />
        <Route
          path="/admin/orders"
          element={<RouteProtector Components={Students} />}
        />
        <Route path="/admin/orders/:id" element={<StudentsPage />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      <ToastContainer />
      <Footer />
    </>
  );
}

export default App;
